/* eslint-disable */
export function setDiseaseKeyValue () {
  const diseaseMeta = document.querySelector('meta[name="dfp_diseases"]');
  if (diseaseMeta) {
    const dfpDisease = diseaseMeta.content.split(',');
    googletag.pubads().setTargeting('disease', dfpDisease);
  }
}

export function setSymptomKeyValue () {
  const symptomMeta = document.querySelector('meta[name="dfp_symptoms"]');
  if (symptomMeta) {
    const dfpSymptom = symptomMeta.content.split(',');
    googletag.pubads().setTargeting('symptom', dfpSymptom);
  }
}

export function setContentTypeKeyValue () {
  const contentMeta = document.querySelector('meta[name="dfp_content"]');
  if (contentMeta) {
    const dfpContentType = contentMeta.content;
    googletag.pubads().setTargeting('contents', [dfpContentType]);
  }
}

export function setHospitalDepartmentKeyValue () {
  const hospitalDeptMeta = document.querySelector('meta[name="dfp_hospital_dept"]');
  if (hospitalDeptMeta) {
    const dfpHospitalDept = hospitalDeptMeta.content.split(',');
    googletag.pubads().setTargeting('hospital_dept', dfpHospitalDept);
  }
}

export function setEnvKeyValue () {
  const envMeta = document.querySelector('meta[name="dfp_env"]');
  if (envMeta) {
    const dfpEnv = envMeta.content;
    googletag.pubads().setTargeting('env', [dfpEnv]);
  }
}

export function setAdTagKeyValue () {
  const adTagMeta = document.querySelector('meta[name="dfp_ad_tags"]');
  if (adTagMeta) {
    const dfpAdTag = adTagMeta.content.split(',');
    googletag.pubads().setTargeting('ad_tag', dfpAdTag);
  }
}

export function setPathKeyValue () {
  const pathMeta = document.querySelector('meta[name="dfp_path"]');
  if (pathMeta) {
    const dfpPath = pathMeta.content;
    googletag.pubads().setTargeting('path', [dfpPath]);
  }
}

export function setDoctorKeyValue () {
  const doctorMeta = document.querySelector('meta[name="dfp_doctor"]');
  if (doctorMeta) {
    const dfpDoctorCode = doctorMeta.content;
    googletag.pubads().setTargeting('doctor', [dfpDoctorCode]);
  }
}

export function isMobile () {
  const ua = navigator.userAgent;
  return ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
}

export function spTopSizes () {
  return [[320, 100], [320, 50], [320, 160]];
}

export function spBottomSizes () {
  return [[336, 340], [336, 280], [300, 250], [300, 310], [320, 100], [320, 50]];
}

export function setDfpResponsiveSize () {
  return googletag.sizeMapping()
    .addSize([336, 0], [[336, 280], [336, 340], [300, 250], [300, 310]])
    .addSize([0, 0], [[300, 250], [300, 310]])
    .build();
}
/* eslint-enable */
